import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class CookieHelper {

  public save<T>(key: string, value: T): void {
    window.localStorage.removeItem(key);
    window.localStorage.setItem(key, JSON.stringify(value));
  }

  public remove(key: string): void {
    window.localStorage.removeItem(key);
  }

  public get<T>(key: string): T | null {
    const value = window.localStorage.getItem(key);
    if (value) {
      return JSON.parse(value) as T;
    }
    return null;
  }

  public saveToSession<T>(key: string, value: T): void {
    window.sessionStorage.removeItem(key);
    window.sessionStorage.setItem(key, JSON.stringify(value));
  }

  public removeFromSession(key: string): void {
    window.sessionStorage.removeItem(key);
  }

  public getFromSession<T>(key: string): T | null {
    const value = window.sessionStorage.getItem(key);
    if (value) {
      return JSON.parse(value) as T;
    }
    return null;
  }
}
