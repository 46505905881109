export function getByteSize(value: string) {
  return new Blob([value]).size;
}

/**
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 *
 * @return Formatted string.
 */
export function toHumanReadableFileSize(bytes: number, si = false, dp = 1) {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


  return bytes.toFixed(dp) + ' ' + units[u];
}


export function getSearchExcerpt(
  terms: string | string[],
  text: string,
  options?: {
    padding?: number,
    highlightClass?: string
  }): string | undefined {

  terms = terms || '';
  text = text || '';
  options = options || {};

  options.padding = options.padding || 100;
  options.highlightClass = options.highlightClass || 'text-primary';

  //join arrays into a string to sanitize the regex
  if (terms instanceof Array) {
    terms = terms.join(' ');
  }
  //sanitize the regex
  terms = terms.replace(/[-[]\/\{}\(\)\*\+\?\.\\\^\$\|"]/g, '\\$&');

  //now split back into array
  terms = terms.split(' ');

  const termRegex = new RegExp('(' + terms.join('|') + ')', 'gi'),
    location = text.search(termRegex);
  if (location !== -1) {
    //calculate the from - to positions
    //add +1 so that we can go back and make sure we ended on a solid word boundary.
    //this prevents us from chopping off a full word unecessarily if the padding
    //happens to fall directly on a word boundary
    const f = Math.max(0, location - (options.padding + 1));
    const t = Math.min(text.length, location + (options.padding + 1));
    let excerpt = text.substring(f, t);

    //ensure we start and end on a word boundary
    if (f !== 0) {
      excerpt = excerpt.replace(/^\S*\s/, '');
    }
    if (t !== text.length) {
      excerpt = excerpt.replace(/\s\S*$/, '');
    }

    //now we highlight the search term
    excerpt = excerpt.replace(termRegex, function(s) {
      return '<span class=\'' + options.highlightClass + '\'>' + s + '</span>';
    });
    return excerpt;
  } else {
    return undefined;
  }
}

export function makeUniqueId(length: number) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}
